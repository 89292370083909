/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-11-15",
    versionChannel: "nightly",
    buildDate: "2024-11-15T00:06:52.985Z",
    commitHash: "af14dea6357b30049c5e83e5a95a4c9a84c16bcc",
};
